const student_bills_invoice = {
  path: '/studentBills/showBills/student_id/:student_id/student_name/:student_name',
  name: 'studentShowBills',
  component: () => import('@/views/account/bills/studentBillsShowBills.vue'),
  beforeEnter(to, from, next) {
    if (
      localStorage.getItem('accessToken') &&
      (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
        JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
    ) {
      next()
    } else {
      next('/')
    }
  },
  meta: {
    layout: 'content',
  },
}

export default student_bills_invoice
