const sections = {
  path: '/services/section',
  name: 'sectionOther',
  component: () => import('@/views/account/bills/services/section_other.vue'),
  beforeEnter(to, from, next) {
    if (
      localStorage.getItem('accessToken') &&
      (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
        JSON.parse(localStorage.getItem('results')).account_type === 'accountant')
    ) {
      next()
    } else {
      next('/')
    }
  },
  meta: {
    layout: 'content',
  },
}

export default sections
