import student_bills from '@/router/bills/student_salary/bill.js'
import student_bills_invoice from '@/router/bills/student_salary/invoice.js'
import sections from '@/router/bills/student_salary/section.js'
import services from '@/router/bills/student_salary/service.js'
import Vue from 'vue'
import VueRouter from 'vue-router'
/* eslint-disable */
Vue.use(VueRouter)

// import localStorage from '../defaultToken/defaultToken'
const routes = [
  // {
  //       path: '/',
  //       redirect: 'login',
  //   },

  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),

    meta: {
      layout: 'blank',
      reload: true,
    },
  },

  {
    path: '/studentHasNoBills',
    name: 'studentHasNoBills',
    component: () => import('@/views/account/studentThatHasNoBills/studentThatHasNoBills.vue'),
    // beforeEnter(to, from, next) {
    //     if (
    //         localStorage.getItem('accessToken') &&
    //         JSON.parse(localStorage.getItem('results')).account_type === 'manager'
    //     ) {
    //         next()
    //     } else {
    //         next('/')
    //     }
    // },
    meta: {
      layout: 'content',
    },
  },

  student_bills,

  {
    path: '/studentBills/studentBillsTransferred',
    name: 'studentTransferred',
    component: () => import('@/views/account/bills/studentBillsTransferred.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/studentBillsDiscounts',
    name: 'studentBillsDiscounts',
    component: () => import('@/views/account/bills/studentBillsDiscounts.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/studentBillInvoice',
    name: 'studentBillInvoice',
    component: () => import('@/views/account/bills/invoices/student/billInvoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'blank',
    },
  },

  {
    path:
      '/studentBills/showBills/student_id/:student_id/student_name/:student_name/showDetails/invoice_id/:invoice_id',
    name: 'studentBillsPaymentShow',
    component: () => import('@/views/account/bills/studentBill/studentPayment.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  student_bills_invoice,

  {
    path: '/studentBillDetailsInvoice',
    name: 'studentBillDetailsInvoice',
    component: () => import('@/views/account/bills/invoices/student/billDetailsInvoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/anotherBillDetailsInvoice',
    name: 'anotherBillDetailsInvoice',
    component: () => import('@/views/account/bills/invoices/another/billDetailsInvoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/anotherBillInvoice',
    name: 'anotherBillInvoice',
    component: () => import('@/views/account/bills/invoices/another/billInvoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/anotherBills',
    name: 'anotherBills',
    component: () => import('@/views/account/bills/anotherBills.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/anotherBills/showBills/name/:name',
    name: 'otherBillsShowBills',
    component: () => import('@/views/account/bills/otherBillsShowBills.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/anotherBills/showBills/customer_name/:customer_name/name/:name/showDetails/id/:id',
    name: 'anotherBillsPayment',
    component: () => import('@/views/account/bills/anotherBill/anotherBillPayment.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  sections,

  //services
  services,

  //expenses
  {
    path: '/expenses',
    name: 'expenses',
    component: () => import('@/views/account/expenses/expenses.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/expensesType',
    name: 'expensesType',
    component: () => import('@/views/account/expenses/expensesType.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/invoice/:id',
    name: 'invoice',
    component: () => import('@/views/account/bills/invoice.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  // actions
  {
    path: '/actions',
    name: 'actions-select',
    component: () => import('@/views/account/staf/actions/selectAction.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  // salaries
  {
    path: '/salariesShow',
    name: 'salariesShow',
    component: () => import('@/views/account/staf/salaries/salariesShow.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/salariesShowDiscounts',
    name: 'salariesShowDiscounts',
    component: () => import('@/views/account/staf/salaries/salariesShowDiscounts.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/salariesShow/add',
    name: 'salariesShowAdd',
    component: () => import('@/views/account/staf/salaries/salariesAdd/salariesAdd.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/salariesShow/edit',
    name: 'salariesShowEdit',
    component: () => import('@/views/account/staf/salaries/salariesEdit/salariesEdit.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  // store
  {
    path: '/store/selling',
    name: 'storeSelling',
    component: () => import('@/views/account/store/storeSelling.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/store/selling/count',
    name: 'storeSellingCount',
    component: () => import('@/views/account/store/storeSellingCount.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/store/material',
    name: 'material',
    component: () => import('@/views/account/store/material.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/store/suppliers',
    name: 'suppliers',
    component: () => import('@/views/account/store/suppliers.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
          JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/account/reports/reports.vue'),
    beforeEnter(to, from, next) {
      if (
        localStorage.getItem('accessToken') &&
        JSON.parse(localStorage.getItem('results')).account_type === 'manager'
      ) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //paymentRequired
  {
    path: '/paymentRequired',
    name: 'paymentRequired',
    component: () => import('@/views/paymentRequired/paymentRequired.vue'),
    beforeEnter(to, from, next) {
      if (localStorage.getItem('accessToken')) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      layout: 'content',
    },
  },

  //setting
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting/setting.vue'),
    // beforeEnter(to, from, next) {
    //     if (localStorage.getItem('accessToken') && JSON.parse(localStorage.getItem('results')).account_type === 'admin') {
    //         next()
    //     } else {
    //         next('/')
    //     }
    // },
    meta: {
      layout: 'content',
    },
  },

  // {
  //   path: '*',
  //   redirect: 'error-404',
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})

export default router
