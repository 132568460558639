const student_bills = {
  path: '/studentBills',
  name: 'studentBills',
  component: () => import('@/views/account/bills/student_bills/bills/show.vue'),
  // beforeEnter(to, from, next) {
  //     if (
  //         localStorage.getItem('accessToken') &&
  //         JSON.parse(localStorage.getItem('results')).account_type === 'manager'
  //     ) {
  //         next()
  //     } else {
  //         next('/')
  //     }
  // },
  meta: {
    layout: 'content',
  },
}

export default student_bills
