/* eslint-disable */

// const results = resultsLocalStorage.features_finger_id

import { mdiCashMultiple, mdiFileDocumentEdit } from '@mdi/js'

export default [
  //Bills
  {
    title: 'فواتير المبالغ الواردة',
    icon: mdiFileDocumentEdit,
    children: [
      {
        title: 'فواتير الطلاب',
        // icon: mdiContentCopy,
        to: 'studentBills',

        // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
      },
      // {
      //   title: 'فواتير اخرى',
      //   to: 'anotherBills',
      //   disabled: true,
      //   // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
      // },
      {
        title: 'ادارة الخدمات',
        to: {
          name: 'services',
        },
      },
    ],
  },

  // employee
  {
    title: 'ادارة الموظفين',
    icon: 'fa-user-graduate',
    children: [
      {
        title: 'ادارة العمليات',
        to: {
          name: 'actions-select',
        },
        // alias: ['actions-add'],
      },
      {
        title: 'الرواتب',
        to: 'salariesShow',
        // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
      },
      // {
      //     title: 'ادارة الخدمات',
      //     href: 'http://localhost:8080/#/services',
      // },
    ],
  },

  {
    title: 'المصاريف',
    icon: mdiCashMultiple,
    children: [
      {
        title: 'ادارة المصاريف',
        to: {
          name: 'expenses',
        },
        // alias: ['actions-add'],
      },
      {
        title: 'ادارة انواع المصاريف',
        to: 'expensesType',
        // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
      },
      // {
      //     title: 'ادارة الخدمات',
      //     href: 'http://localhost:8080/#/services',
      // },
    ],
  },

  // {
  //     title: 'المخازن',
  //     icon: "fa-layer-group",
  //     children: [{
  //             title: 'الرصيد المخزني',
  //             to: {
  //                 name: 'storeSelling'
  //             },
  //             // alias: ['actions-add'],
  //         },
  //         {
  //             title: 'مواد المخزن',
  //             to: 'material',
  //             // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
  //         },
  //         {
  //             title: 'المجهزين',
  //             to: 'suppliers',
  //             // alias: ['/studentInClass', '/addStudent', '/studentProfile'],
  //         },
  //     ],
  // },

  // {
  //     title: 'التقارير',
  //     icon: mdiChartBar,
  //     to: 'reports',
  // },
]
