import axios from 'axios'
import sha512 from 'js-sha512'

class Api {
  async login(account_email, account_password, auth_phone_details, auth_ip, auth_city, auth_lat, auth_lon) {
    return axios
      .post('/login', {
        account_email,
        account_password: sha512(account_password),
        auth_phone_details,
        auth_ip,
        auth_city,
        auth_lat,
        auth_lon,

        // auth_phone_id: authData.firebaseToken,
        // auth_firebase: authData.firebaseToken,
      })
      .then(Response => ({
        error: false,
        results: Response,
      }))
      .catch(() => ({
        error: true,
        results: [],
      }))
  }

  // class School
  async getClassSchool() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get('/classSchool')
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  //Service
  async getService(type) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/services/type/${type}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addService(name, type) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/services/add`, {
        name,
        type,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editService(name, id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/services/edit`, {
        name,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeService(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/services/delete/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  //student Bills

  async studentSendNotification({ account_id, study_year, notificationBody }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/student/sendNotification`, {
        account_id,
        study_year,
        notificationBody,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getStudentThatHasNoBills({ study_year, page, limit, search, class_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/getStudentThatHasNoBills`, {
        study_year,
        page,
        limit,
        search,
        class_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async transferredStudentDB({ is_transferred, transferred_note, student_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/studentSalary/updateIsTransferred`, {
        is_transferred,
        transferred_note,
        student_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getStudentBills({ is_transferred, isDeleted, isPaid, start_date, end_date, study_year, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/getAll`, {
        is_transferred,
        isDeleted,
        study_year,
        page,
        limit,
        search,
        isPaid,
        start_date,
        end_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getStudentTransferredBills({
    is_transferred,
    isDeleted,
    isPaid,
    start_date,
    end_date,
    study_year,
    page,
    limit,
    search,
    class_id,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/getAllStudentTransferred`, {
        is_transferred,
        isDeleted,
        study_year,
        page,
        limit,
        search,
        isPaid,
        start_date,
        end_date,
        class_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAllStudentBills({
    isDeleted,
    isPaid,
    start_date,
    end_date,
    study_year,
    search,
    class_school_id,
    is_transferred,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/getAllWithoutPagination`, {
        isDeleted,
        study_year,
        search,
        isPaid,
        start_date,
        end_date,
        class_school_id,
        is_transferred,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getStudentBillsDiscounts({ isDeleted, isPaid, start_date, end_date, study_year, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/getAllDiscounts`, {
        isDeleted,
        study_year,
        page,
        limit,
        search,
        isPaid,
        start_date,
        end_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getOneStudentBills({ isDeleted, start_date, end_date, study_year, student_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/getAllInvoice`, {
        isDeleted,
        start_date,
        end_date,
        study_year,
        student_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeOneStudentBills(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/studentSalary/deleteInvoice/id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getStudentPayment({ isDeleted, study_year, invoice_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/getInvoice`, {
        isDeleted,
        study_year,
        invoice_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addStudentPayment(payments, invoice_id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/addPayment`, {
        payments,
        invoice_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editStudentPayment({ amount, type, desc, next_payment, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/studentSalary/editInvoice`, {
        amount,
        type,
        desc,
        next_payment,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addStudentBills({ student_id, date, payments, desc, service_type }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/studentSalary/addInvoice`, {
        student_id,
        payments,
        desc,
        date,
        service_type,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeStudentBills(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/studentSalary/deleteAll/student_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeStudentPayment(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/studentSalary/deletePayment/id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // another bills
  async otherSalary({ isDeleted, isPaid, start_date, end_date, study_year, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/getAll`, {
        isDeleted,
        isPaid,
        start_date,
        end_date,
        study_year,
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getOneOtherBills({ isDeleted, isPaid, start_date, end_date, study_year, name }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/getAllInvoice`, {
        isDeleted,
        isPaid,
        start_date,
        end_date,
        study_year,
        name,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeOtherBills(name) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/otherSalary/deleteAll/name/${name}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addOtherBills({ name, date, payments, desc, mobile, service_type }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/addInvoice`, {
        name,
        date,
        payments,
        desc,
        mobile,
        service_type,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeOneOtherBills(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/otherSalary/deleteInvoice/id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAnotherPayment({ isDeleted, study_year, invoice_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/getInvoice`, {
        isDeleted,
        study_year,
        invoice_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addAnotherPayment(payments, invoice_id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/otherSalary/addPayment`, {
        payments,
        invoice_id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editAnotherPayment({ amount, type, desc, next_payment, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/otherSalary/editInvoice`, {
        amount,
        type,
        desc,
        next_payment,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeAnotherPayment(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/otherSalary/deletePayment/id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeStudentFromStudentThatHasNoBills(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/studentSalary/removeStudentThatHasNoBills/account_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // actions
  async getActions(type) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/services_salaries/type/${type}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addActions(name, type) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/services_salaries/add`, {
        name,
        type,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editActions(name, id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/services_salaries/edit`, {
        name,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeActions(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/services_salaries/delete/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  //salaries
  async getSalaries({ isDeleted, start_date, end_date, study_year, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/getSalary`, {
        isDeleted,
        start_date,
        end_date,
        study_year,
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAllSalaries({ isDeleted, start_date, end_date, study_year, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/getAllSalary`, {
        isDeleted,
        start_date,
        end_date,
        study_year,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getSingeSalaries({ isDeleted, study_year, search, salary_id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/getSingleSalary`, {
        salary_id,
        isDeleted,
        study_year,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getSalariesDiscounts({ isDeleted, start_date, end_date, study_year, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/getSalaryDiscounts`, {
        isDeleted,
        start_date,
        end_date,
        study_year,
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // student
  async getAllStudents() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .get('/student/getAll')
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // get student
  async getEmployee(study_year) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .get(`/counting/staff/getStaff/study_year/${study_year}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addEmployeeSalary({
    account_id,
    amount,
    discounts,
    additional,
    lectures_number,
    per_lectures_price,
    all_lectures_price,
    lectures_class_school,
    lectures_note,
    per_watch_price,
    watch_number,
    watch_note,
    study_year,
    payment_date,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/staff/addSalary`, {
        account_id,
        amount,
        discounts,
        additional,
        lectures_number,
        per_lectures_price,
        all_lectures_price,
        lectures_class_school,
        lectures_note,
        per_watch_price,
        watch_number,
        watch_note,
        study_year,
        payment_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editEmployeeSalary({
    amount,
    discounts,
    additional,
    all_lectures_price,
    lectures_note,
    per_watch_price,
    watch_number,
    watch_note,
    payment_date,
    _id,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/staff/editSalary`, {
        amount,
        discounts,
        additional,
        all_lectures_price,
        lectures_note,
        per_watch_price,
        watch_number,
        watch_note,
        payment_date,
        _id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeEmployee(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .delete(`/counting/staff/delStaff/id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // services_expenses
  async getServiceExpenses() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/services_expenses/get`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addServiceExpenses(name, note) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/services_expenses/add`, {
        name,
        note,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editServiceExpenses(name, note, id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/services_expenses/edit`, {
        name,
        note,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeServiceExpenses(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/services_expenses/delete/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // expenses

  async getExpenses({ study_year, start_date, end_date, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/expenses/get`, {
        study_year,
        start_date,
        end_date,
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addExpenses({ expenses_name, money, date, note, study_year }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/expenses/add`, {
        expenses_name,
        money,
        date,
        note,
        study_year,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editExpenses({ expenses_name, money, date, note, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/expenses/edit`, {
        expenses_name,
        money,
        date,
        note,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeExpenses(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/expenses/delete/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // reports
  async getReports(study_year, start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/analysis/invoice`, {
        study_year,
        start_date,
        end_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getReportsExpenses(study_year, date, start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/analysis/expenses`, {
        study_year,
        date,
        start_date,
        end_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getReportsSalary(study_year, start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`counting/analysis/incomes`, {
        study_year,
        start_date,
        end_date,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // mainData
  async mainData() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .get(`/mainData`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // suppliers
  async getSuppliers({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/suppliers/get`, {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getSuppliersNameAndId() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/suppliers/getNameAndId`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAllSuppliers() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/suppliers/getAll`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addSuppliers({ store_suppliers_name, store_suppliers_mobile, store_suppliers_address }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/suppliers/add`, {
        store_suppliers_name,
        store_suppliers_mobile,
        store_suppliers_address,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editSuppliers({ store_suppliers_name, store_suppliers_mobile, store_suppliers_address, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/store/suppliers/edit`, {
        store_suppliers_name,
        store_suppliers_mobile,
        store_suppliers_address,
        suppliers_id: id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeSuppliers(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/store/suppliers/remove/suppliers_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // material
  async getMaterial({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/material/get`, {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAllMaterial() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/material/getAll`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getMaterialBelongToSupplier(supplier_id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/material/get/supplier_id/${supplier_id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addMaterial({ store_material_name, store_material_for_suppliers }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/material/add`, {
        store_material_name,
        store_material_for_suppliers,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editMaterial({ store_material_name, store_material_for_suppliers, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/store/material/edit`, {
        store_material_name,
        store_material_for_suppliers,
        material_id: id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeMaterial(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/store/material/remove/material_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  // storeSelling
  async getStoreSelling({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/storeSelling/get`, {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getAllStoreSelling() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .get(`/counting/store/storeSelling/getAll`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async getStoreSellingGroup({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/storeSelling/getGroup`, {
        page,
        limit,
        search,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async addStoreSelling({
    store_selling_for_suppliers,
    store_selling_for_material,
    store_selling_quantity,
    store_selling_details,
    store_selling_price,
    store_selling_paid_price,
    store_selling_deserve_date,
    store_selling_date,
    store_selling_measure,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/store/storeSelling/add`, {
        store_selling_for_suppliers,
        store_selling_for_material,
        store_selling_quantity,
        store_selling_details,
        store_selling_price,
        store_selling_paid_price,
        store_selling_deserve_date,
        store_selling_date,
        store_selling_measure,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async editStoreSelling({
    store_selling_for_suppliers,
    store_selling_for_material,
    store_selling_quantity,
    store_selling_details,
    store_selling_price,
    store_selling_paid_price,
    store_selling_deserve_date,
    store_selling_date,
    store_selling_measure,
    id,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/store/storeSelling/edit`, {
        store_selling_for_suppliers,
        store_selling_for_material,
        store_selling_quantity,
        store_selling_details,
        store_selling_price,
        store_selling_paid_price,
        store_selling_deserve_date,
        store_selling_date,
        store_selling_measure,
        store_selling_id: id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async removeStoreSelling(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/store/storeSelling/remove/store_selling_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async edit() {}
  async remove() {}
  async add() {}
}

export default new Api()
