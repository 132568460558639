const services = {
  path: '/services',
  name: 'services',
  component: () => import('@/views/account/bills/services/services.vue'),
  beforeEnter(to, from, next) {
    if (
      localStorage.getItem('accessToken') &&
      (JSON.parse(localStorage.getItem('results')).account_type === 'manager' ||
        JSON.parse(localStorage.getItem('results')).account_type === 'accountantPrivilege')
    ) {
      next()
    } else {
      next('/')
    }
  },
  meta: {
    layout: 'content',
  },
}

export default services
